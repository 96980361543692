<template>
  <div>
    <section class="section-info section-info-sm" id="redirection">
      <div class="jcard-main jcard-dark">
        <div class="duke-spinner">
          <img src="@/assets/spinners/no-holes-square/tourbillon-grey.svg" alt="Tourbillon" />
        </div>
        <h3 class="mt-5">Thank you!</h3>
        <p class="p1-platform">Please wait...</p>

        <p class="mt-3 p-text">We are preparing your Watch. You will be redirected to your new Watch shortly.</p>

        <div v-if="checkConnection" class="mt-3">
          <p class="small p-text mt-3">
            This taking longer than usual, please check your internet connection.
          </p>
        </div>

        <!-- manual refresh in case snapshot is not working -->
        <div v-if="manualRedirect" class="mt-3">
          <p class="small p-text mt-3">Could not finalize payment. Please check your payments, if something is missing
            make
            sure to contact our support team.</p>

          <button class="mt-3 jbtn jbtn-white" @click="redirectManually">Continue</button>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import C from "@/const";
import toast from "@/assets/js/toast";
import firebase from "@/firebase/firebaseInit"
const db = firebase.db;
const auth = firebase.auth;

export default {
  name: "Redirection",
  data() {
    return {
      listenerRegistration: null,
      checkConnection: false,
      manualRedirect: false,
      timer: null,
    }
  },
  methods: {
    init() {
      console.log("init");
      if (!auth.currentUser)
        return;

      if (this.listenerRegistration != null)
        this.listenerRegistration();


      const userRef = db.collection(C.COLLECTION.USERS)
        .doc(auth.currentUser.uid);
      this.listenerRegistration = userRef
        .onSnapshot((snap) => {
          const user = snap.data();
          // console.log("user: " + user);
          // if (user.status == 1) {
          //   console.log("USER GOT RIGHT SATUS");
          // } else {
          //   // TODO: handle payment pending etc
          //   console.log("NOT right status");
          // }

          if (user.status == 1 && user.refresh == true) {
            console.log("token refresh required");

            //backend has created the watch and updated the claims-role, we now need to refresh the token and then redirect the user
            // TODO: use promises instead of commit => store needs refactoring also idTokenResult != auth.currentuser....
            this.$store.commit("setUser", user);
            auth
              .currentUser.getIdTokenResult(true)
              .then((idTokenResult) => {
                // if (idTokenResult.claims.admin) {
                //   console.log("is admin");
                // } else if (idTokenResult.claims.press) {
                //   console.log("is press");
                // } else if (idTokenResult.claims.member) {
                //   console.log("is Member");
                // }

                // TODO: maybe use async logic to be sure this done before redirecting?
                userRef.update({
                  refresh: false
                });
                this.$store.commit("setClaims", idTokenResult.claims);
                this.$store.dispatch("updateUser", auth.currentUser);
                if (!C.PRODUCTION)
                  console.log(idTokenResult);

                this.$router.replace({ name: 'MyWatches' })
              });
          }

        }, (error) => {
          console.log(error);
          toast.error("Please check your internet connection");
        });
    },
    setCountdownTimer() {
      var seconds = 0;
      this.timer = setInterval(() => {
        seconds++;
        console.log("Waiting time: " + seconds);
        if (seconds % C.RETRY_INTERVAL == 0)
          this.init()
        if (seconds > C.CONNECTION_WARNING)
          this.checkConnection = true;
        if (seconds > C.MAXIMUM_WAITING_TIME) {
          this.checkConnection = false;
          clearInterval(this.timer);
          this.manualRedirect = true;
        }
      }, 1000);
    },
    redirectManually() {
      // refresh token, then go to my watches
      auth
        .currentUser.getIdTokenResult(true)
        .then((idTokenResult) => {
          this.$store.dispatch("updateUser", idTokenResult);
          // if (idTokenResult.claims.admin) {
          //   console.log("is admin");
          // } else if (idTokenResult.claims.press) {
          //   console.log("is press");
          // } else if (idTokenResult.claims.member) {
          //   console.log("is Member");
          // }

          // TODO: maybe use async logic to be sure this done before redirecting?
          db.collection(C.COLLECTION.USERS).doc(auth.currentUser.uid).update({
            refresh: false
          });

          this.$router.replace({ name: 'MyWatches' })
        });
    },
  },
  computed: {
    userDoc: {
      get() {
        return this.$store.getters.getUser;
      },
    },
  },
  created() {
    this.init();
    this.setCountdownTimer();
  },
  // cleanup of timer and listener
  beforeDestroy() {
    if (this.listenerRegistration != null) this.listenerRegistration();
    if (this.timer) clearInterval(this.timer);
  }
};
</script>

<style scoped></style>
